<template>
    <div class='user-edit'>
        <h5 class="p-d-inline p-pt-0 user-edit-title">{{ formTitle }}</h5>
        <TabView
            class='edit-form__tabs'
        >
            <TabPanel
                v-for='(tab, index) in accessibleItems(tabs)'
                :active.sync='activeTabs[index]'
                :key='index'
                :header='tab.label'
                :disabled='tab.disabled'
            >
                <component
                    v-if='activeTabs[index]'
                    :is='tab.component'
                    v-bind='tab.props'
                    @publish='onPublish'
                    @change-data='changedData = $event'
                />
            </TabPanel>
        </TabView>
        <div
            v-if='activeTab.component === "Params"'
            class='edit-form__actions'
        >
            <Button class="p-button" label="Сохранить" @click="acceptAction"/>
            <Button class="p-button-outlined" label="Отменить" @click='cancelAction'/>
        </div>
    </div>
</template>

<script>
import { getUserById } from '@/api/user';
import { USERS_FORM_TITLES } from '@/constants/users';
import Params from '@/pages/users/userEditTabs/Params';
import FormPermissions from '@/pages/users/userEditTabs/FormPermissions';
import { USER_PERMISSIONS_MAP } from '@/constants/common';
import {mapGetters} from "vuex";

const { formVerificationRules } = USER_PERMISSIONS_MAP;

export default {
    name: 'newUser',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
        userId: {
            type: [String, Number],
            default: ''
        },
        orgId: {
            type: [String, Number],
            default: ''
        }
    },
    components: {
        Params,
        FormPermissions
    },
    data() {
        this.editTitle = USERS_FORM_TITLES.EDIT
        this.createTitle = USERS_FORM_TITLES.CREATE
        return {
            activeTabs: [true, false],
            changedData: false,
            acceptAction: () => {}
        }
    },
    methods: {
        onPublish(payload) {
            const { acceptAction } = payload
            this.acceptAction = acceptAction
        },
        cancelAction() {
            window.history.back()
        },

        /** Проверка наличия у пользователя права formVerificationRules
         * с целью назначения прав на формы напрямую.
         * В противном случае, вкладка "Права по формам" блокируется.
         */
        async isAvaliableFormRights() {
            const { data: { relationships } } = await getUserById(this.userId);
            let permissionsObject = [];
            relationships.permissions.data.forEach(({ id }) => { permissionsObject[id] = true });
            const rightsTab = this.tabs.find(({ component }) => component === 'FormPermissions');
            rightsTab.disabled = ![ formVerificationRules ].some(p => permissionsObject[p]);
        }
    },

    async mounted() {
        if (this.userId) {
            await this.isAvaliableFormRights();
        }
    },

    computed: {
        ...mapGetters('auth', [ 'accessibleItems' ]),
        isEditMode() {
            return !!this.userId
        },
        formTitle() {
            return this.isEditMode ? this.editTitle : this.createTitle
        },
        activeTab() {
            const activeTabIdx = this.activeTabs.findIndex(i => i)
            return this.tabs[activeTabIdx]
        },
        tabs() {
            return [
                {
                    label: 'Параметры',
                    disabled: false,
                    component: 'Params',
                    props: {
                        orgId: this.orgId,
                        userId: this.userId,
                        isEditMode: !!this.userId
                    }
                },
                {
                    label: 'Права по формам',
                    disabled: false,
                    component: 'FormPermissions',
                    props: {
                        userId: this.userId
                    },
                }
            ];
        },
    },

    watch: {
        activeTabs: {
            handler() {
                if (this.changedData) {
                    this.$root.$emit('show-leave-dialog', {
                        acceptCb: async () => await this.acceptAction(),
                        beforeClose: () => {
                            this.changedData = false
                            this.acceptAction = null
                        }
                    })
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.changedData) {
            this.$root.$emit('show-leave-dialog', {
                acceptCb: async () => await this.acceptAction(),
                beforeClose: next
            })
            next(false)
        } else {
            next()
        }
    }
};
</script>

<style scoped lang="scss">
.my-form {
    background-color: #f8f9fa;
    border-radius: 4px;
    //padding: 1rem;
}

.user-edit {
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr min-content;

    ::v-deep {
        .p-tabview-panels {
            padding: 12px 0 0 0 !important;
            display: grid;
            height: 95%;
        }

        .edit-form {
            //overflow-x: auto;
            height: 100%;

            .field-label {
                min-height: 13px;
                width: 100%;
            }

            .field-checkbox {
                min-height: 31px;

                &-label {
                    margin-left: 8px;
                }
            }
        }
    }

    &-title {
        margin-bottom: 0;
    }
}
</style>
